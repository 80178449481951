<template>
  <div
    id="mfa-configuration-modal"
    ref="mfa-configuration-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            MFA Configuration
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <CheckboxInput
            v-model="isUsingMFA"
            label="Use Two-Factor Authentication"
            description="Check this if You want to use two factor authentication"
          />
          <div class="d-flex justify-content-center align-items-center">
            <img :src="qrCodeUrl" />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <button
            class="btn btn-primary"
            data-bs-dismiss="modal"
            type="button"
          >
            Cancel
          </button>
          <button
            class="btn btn-success"
            @click="showConfirmationModal"
          >
            Save MFA configuration
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="save-mfa-config-confirmation-modal"
    :onCancelHandler="hideConfirmationModal"
    :onConfirmHandler="saveConfigurationClickHandler"
  >
    <template #content>
      <div
        v-if="isUsingMFA"
        class="alert alert-warning"
        role="alert"
      >
        Please make sure that You scanned the QR-code
        and added it to your Google Authentication application
      </div>
      <div
        v-else
      >
        Do You really want to disable Two Factor Authentication for your account?
        It can lead to security issues in future.
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Modal } from 'bootstrap';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import CheckboxInput from '@/components/common/CheckboxInput';

export default {
  components: {
    CheckboxInput,
    ConfirmationModal
  },
  data() {
    return {
      isUsingMFA: false,
      mfaConfigurationModal: null
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'userProfile/userProfile'
    }),
    qrCodeUrl() {
      return this.userProfile.qrCodeUrl;
    }
  },
  mounted() {
    this.mfaConfigurationModal = new Modal(this.$refs['mfa-configuration-modal']);
  },
  methods: {
    ...mapActions({
      updateMFAConfiguration: 'userProfile/updateMFAConfiguration',
      fetchUserProfile: 'userProfile/fetchUserProfile'
    }),
    show() {
      this.isUsingMFA = this.userProfile.usingMFA;
      this.mfaConfigurationModal.show();
    },
    async saveConfigurationClickHandler() {
      await this.updateMFAConfiguration(this.isUsingMFA);
      await this.fetchUserProfile();
      this.$refs['save-mfa-config-confirmation-modal'].confirmationModal.hide();
      this.mfaConfigurationModal.hide();
    },
    hideConfirmationModal() {
      this.$refs['save-mfa-config-confirmation-modal'].confirmationModal.hide();
    },
    showConfirmationModal() {
      this.$refs['save-mfa-config-confirmation-modal'].confirmationModal.show();
    }
  }
};
</script>
