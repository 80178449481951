<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            User profile
          </div>
          <div class="card-body w-100 p-5">
            <div class="profile-container">
              <h4>Profile</h4>
              <TextInput
                v-model="userFullName"
                class="p-2"
                label="User name"
                readonly
              />
              <TextInput
                v-model="userEmail"
                class="p-2"
                label="Email"
                readonly
              />
              <TextInput
                v-model="userRole"
                class="p-2"
                label="User role"
                readonly
              />
              <hr />
              <h4 class="pt-5">
                Multi Factors Authentication
              </h4>
              <p class="p-2">
                <i
                  v-if="userProfile.usingMFA"
                  class="bi bi-check-circle text-success"
                />
                <i
                  v-else
                  class="bi bi-exclamation-triangle text-warning"
                />
                Two factor authentication is {{ userProfile.usingMFA ? '' : 'not' }} configured
              </p>

              <div class="d-flex justify-content-between p-2">
                <button
                  class="btn btn-primary"
                  @click.prevent="configureMFAClickHandler"
                >
                  Configure MFA
                </button>
              </div>
              <hr />
              <h4 class="pt-5">
                Change Password
              </h4>
              <form ref="passForm">
                <TextInput
                  v-model="oldPassword"
                  class="p-2"
                  :errors="v$.oldPassword.$errors"
                  label="Old password"
                  inputType="password"
                  @blur="v$.oldPassword.$touch"
                />
                <TextInput
                  v-model="newPassword"
                  :errors="v$.newPassword.$errors"
                  class="p-2"
                  label="New password"
                  inputType="password"
                  @blur="v$.newPassword.$touch"
                />
                <TextInput
                  v-model="newPasswordConfirmation"
                  :errors="v$.newPasswordConfirmation.$errors"
                  class="p-2"
                  label="New password confirmation"
                  inputType="password"
                  @blur="v$.newPasswordConfirmation.$touch"
                />
                <div class="d-flex justify-content-between p-2">
                  <button
                    class="btn btn-success"
                    type="submit"
                    @click.prevent="updatePassword"
                  >
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MFAConfigurationModal
      ref="mfa-configuration-modal"
    />
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { minLength, requiredIf } from '@vuelidate/validators';
import { passwordStrengthValidator } from '@/lib/validators';
import MFAConfigurationModal from './MFAConfigurationModal';
import { mappedUserRoles } from '@/components/constants';

export default {
  components: {
    TextInput,
    MFAConfigurationModal
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null
    };
  },
  validations() {
    return {
      oldPassword: {
        required: requiredIf(
          () => !_.isEmpty(this.newPassword) || !_.isEmpty(this.newPasswordConfirmation)
        )
      },
      newPassword: {
        required: requiredIf(() => !_.isEmpty(this.oldPassword)),
        minLength: minLength(8),
        passwordStrengthValidator,
        shouldMatchWithConfirmation: {
          $validator: (value) => {
            return value === this.newPasswordConfirmation;
          },
          $message: 'The values for "New password" and "New password confirmation" should be the same'
        }
      },
      newPasswordConfirmation: {
        required: requiredIf(() => !_.isEmpty(this.oldPassword)),
        minLength: minLength(8),
        passwordStrengthValidator,
        shouldMatchWithConfirmation: {
          $validator: (value) => {
            return value === this.newPassword;
          },
          $message: 'The values for "New password" and "New password confirmation" should be the same'
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'userProfile/userProfile',
      userRoles: 'userRole/userRoles',
    }),
    userEmail() {
      return _.get(this.userProfile, 'email');
    },
    userRole() {
      const roleId = _.get(this.userProfile, 'role');
      const userRole = this.userRoles.find((item) => item.id === roleId)?.name;
      return mappedUserRoles[userRole]?.role || userRole;
    },
    userFullName() {
      return _.get(this.userProfile, 'fullName');
    }
  },
  async mounted() {
    await this.fetchUserProfile();
    await this.fetchUserRoles();
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'userProfile/fetchUserProfile',
      updateUserPassword: 'userProfile/updateUserPassword',
      configureMFA: 'userProfile/configureMFA',
      fetchUserRoles: 'userRole/fetchUserRoles',
    }),
    async updatePassword() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      try {
        await this.updateUserPassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        });
        this.$toast.success('Password updated successfully.');
        this.$refs.passForm.reset();
      } catch (e) {
        let message = 'Failed to update password.';
        const errors = _.get(e, 'cause.errorDetails.errors');

        if (errors) {
          message += `<br /> Errors: <br />${errors.join('<br />')}.`;
        }

        this.$toast.error(message);
      }
    },
    async configureMFAClickHandler() {
      await this.configureMFA();
      this.$refs['mfa-configuration-modal'].show();
    }
  }
};
</script>
<style scoped>
.profile-container {
  min-height: 40vh;
}
</style>
